<template>
  <div class="learningAbility">
    <div class="banner">
      <img src="../../../assets/college/Accounts/learningAbility/banner.png" alt="" />
    </div>
    <div class="container">
      <About />
      <School />
    </div>
    <Projects />
    <Service />
    <ul class="sideBar" :style="showIcon ? 'height:120px' : 'height:60px'">
      <li :style="showIcon ? '' : 'border:0'">
        <img src="@/assets/customizedCourse/kefu.png" alt="" />
        <img class="hover" src="@/assets/customizedCourse/kefu_hover.png" alt="" />
        <p>客服</p>
        <div class="tel">
          <img src="@/assets/customizedCourse/tel.png" alt="" />
          <span>17786424575</span>
        </div>
      </li>
      <li :style="showIcon ? 'opacity:1' : 'opacity:0'" @click="moveTo">
        <img src="@/assets/customizedCourse/backTop.png" alt="" />
        <p>置顶</p>
      </li>
    </ul>
  </div>
</template>

<script>
import About from './commonents/LearningAbility/About.vue'
import School from './commonents/LearningAbility/School.vue'
import Projects from './commonents/LearningAbility/Projects.vue'
import Service from './commonents/LearningAbility/Service.vue'
export default {
  components: {
    About,
    School,
    Projects,
    Service,
  },
  data() {
    return {
      showIcon: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollBackTop) //监听滚动条
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBackTop) //移除滚动条监听
  },
  methods: {
    scrollBackTop() {
      const that = this
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 500) {
        that.showIcon = true
      } else {
        that.showIcon = false
      }
    },
    moveTo() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        console.log(ispeed)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
  },
}
</script>

<style scoped lang="scss">
.learningAbility {
  background-color: #fff;
  .banner {
    height: 640px;
  }
  .container {
    width: 1283px;
    margin: auto;
  }
  .sideBar {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: fixed;
    bottom: 175px;
    right: 57px;
    width: 60px;
    height: 120px;
    background: #ffffff;
    border: 1px solid #7e7e7e;
    border-radius: 4px;
    padding: 0 4px;
    box-sizing: border-box;
    &:hover {
      overflow: visible;
    }
    li {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      height: 60px;
      border-bottom: 1px solid #909399;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 26px;
        height: 25px;
        margin-bottom: 5px;
      }
      img.hover {
        display: none;
      }
      &:first-child {
        position: relative;
        padding-top: 6px;
        &:hover {
          transition: all 0.3s;
          position: relative;
          width: 60px;
          height: 60px;
          background: #fca139;
          border-radius: 4px;
          left: -5px;
          top: -1px;
          border: 0;
          p {
            color: #fff;
          }
          > img {
            display: none;
            &.hover {
              display: block;
            }
          }
          .tel {
            transition: all 0.3s;
            opacity: 1;
            right: 60px;
          }
        }
        .tel {
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 30px;
          top: 7px;
          width: 180px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #7e7e7e;
          border-radius: 4px;
          display: flex;
          align-items: center;
          padding-left: 13px;
          box-sizing: border-box;
          img {
            width: 22px;
            height: 22px;
            margin-right: 6px;
            margin-bottom: 0;
          }
          span {
            font-size: 20px;
            font-family: Alibaba-PuHuiTi-Regular;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 46px;
          }
        }
      }
      &:last-child {
        padding-top: 7px;
        border: 0;
        img {
          width: 20px;
          height: 22px;
          margin-bottom: 8px;
        }
      }
      p {
        font-size: 16px;
        font-family: Alibaba-PuHuiTi-Regular;
        font-weight: 400;
        text-align: center;
        color: #909399;
        line-height: 16px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
