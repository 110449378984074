<template>
  <div class="School">
    <div class="title">学校与专业</div>
    <div class="content">
      <img src="../../../../../assets/college/Accounts/learningAbility/schoolBgc.png" alt="" />
      <div class="detail">
        <div class="left">
          <img src="../../../../../assets/college/Accounts/learningAbility/school.png" alt="" />
        </div>
        <div class="right">
          <div class="header">中南财经政法大学——金融、投资专业</div>
          <div class="text">
            <div>
              南财经政法大学是教育部直属的一所以经济学、法学、管理学为主干学科的
              211院校，入选世界一流大学和一流学科（简称“双一流”）建设高校及建设学科 名单。
            </div>
            <div>
              学院金融学科为国家重点学科（top10），也是学校“双一流”建设的重要支
              撑学科。金融学专业是国家级特色专业建设点并获批国家级专业综合改革试点项
              目。金融学院是新中国最早的金融、投资人才培养基地之一。学院于1978年开始
              招收货币银行学专业硕士研究生，1986年获得货币银行学博士学位授予权，1995
              年投资经济博士点成为当时全国高校中唯一的该专业博士点。2014年，金融学院
              荣获"全国教育系统先进集体"称号。学院现有专任教师109人，其中享受国务院政
              府津贴4人，享受湖北省政府津贴2人，入选教育部新世纪优秀人才支持计划4人，
              获得海外博士学位19人。
            </div>
          </div>
          <div class="honor">
            <div class="honorTitle">
              <img src="../../../../../assets/college/Accounts/learningAbility/left.png" alt="" />
              <span>荣誉奖项</span>
              <img src="../../../../../assets/college/Accounts/learningAbility/right.png" alt="" />
            </div>
            <div class="honorContent">
              <div>获第四届全国高校青年教师教学竞赛"文科组"二等奖；</div>
              <div>
                教育部全国第四轮学科评估，应用经济学获评A-，与清华、南开、复旦等高 校并列第八。
              </div>
              <div>第八届"工商银行杯"全国大学生金融创意设计大赛区域总决赛获得一等奖和 二等奖。</div>
              <div>
                教育部全国金融专业硕士学位教学指导委员会，金融学院提交的学位论文均
                全部获奖，获奖总数在参评高校中并列第一！
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.School {
  padding-bottom: 66px;
  .title {
    color: #333333;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 14px;
  }
  .content {
    position: relative;
  }
  .detail {
    position: absolute;
    left: 54px;
    top: 91px;
    display: flex;
    .left {
      width: 353px;
      height: 443px;
      margin-top: 20px;
    }
    .right {
      width: 768px;
      margin-left: 32px;
      .header {
        color: #fda23a;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 33px;
      }
      .text {
        text-indent: 2em;
        color: #333333;
        line-height: 32px;
      }
      .honor {
        margin-top: 54px;
        .honorTitle {
          color: #fda23a;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 27px;
          img {
            width: 129px;
            height: 18px;
          }
          span {
            margin: 0 15px;
          }
        }
        .honorContent {
          display: flex;
          color: #333333;
          line-height: 24px;
          &:nth-child(1) div {
            width: 169px;
          }
          &:nth-child(2) div {
            width: 171px;
            margin-left: 26px;
          }
          &:nth-child(3) div {
            width: 175px;
            margin-left: 27px;
          }
          &:nth-child(4) div {
            width: 180px;
            margin-left: 31px;
          }
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
