<template>
  <div class="Service">
    <div class="content">
      <div class="title">服务流程</div>
      <div class="detail">
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/service1.png" alt="" />
          <div class="bgc">
            <div class="bgcImg">
              <img
                src="../../../../../assets/college/Accounts/learningAbility/serviceBgc.png"
                alt=""
              />
              <div class="num">01</div>
              <div class="explain">录取入学</div>
            </div>
          </div>
          <div class="text">
            <div class="textTitle">免试入学</div>
            <div class="textDetail">
              签订合同或者缴纳申请费后，提供参与 人员基本信息，中潮教育联合中南财经
              政法大学对学员资料进行审核，对符合 要求的学员7日内发放【中南财经政法
              大学】录取通知通知书。
            </div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/service2.png" alt="" />
          <div class="bgc">
            <div class="bgcImg">
              <img
                src="../../../../../assets/college/Accounts/learningAbility/serviceBgc.png"
                alt=""
              />
              <div class="num">02</div>
              <div class="explain">线上学习</div>
            </div>
          </div>
          <div class="text">
            <div class="textTitle">线上学习</div>
            <div class="textDetail">
              <div>
                1.12-14门学位课程（300学时），周末 线上学习，线上考试，中潮为学员定制 学习计划。
              </div>
              <div>
                2.中南财经政法大学硕博导师团队线上直播授课。 3.中潮教育提供答疑、教辅、提醒、学
                习辅助、备考指南等教务服务工作
              </div>
            </div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/service3.png" alt="" />
          <div class="bgc">
            <div class="bgcImg">
              <img
                src="../../../../../assets/college/Accounts/learningAbility/serviceBgc.png"
                alt=""
              />
              <div class="num">03</div>
              <div class="explain">科学备考</div>
            </div>
          </div>
          <div class="text">
            <div class="textTitle">准备两门国家统考</div>
            <div class="textDetail">
              <div>
                1.需要四年内通过2门国家统考考试， 一门英语，一门专业综合课。统考一年
                一次，每年5月第三个周日考试。每位 学员有四年共四次机会。
              </div>
              <div>2.中潮教育提供备考辅导、考试培训等 服务。</div>
            </div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/service4.png" alt="" />
          <div class="bgc">
            <div class="bgcImg">
              <img
                src="../../../../../assets/college/Accounts/learningAbility/serviceBgc.png"
                alt=""
              />
              <div class="num">04</div>
              <div class="explain">论文答辩</div>
            </div>
          </div>
          <div class="text">
            <div class="textTitle">专业辅导</div>
            <div class="textDetail">
              <div>1.通过学校的论文答辩，即可获得学位 证书。</div>
              <div>
                2.中潮教育提供论文撰写辅导（硕博士 团队） 中潮教育邀请教授开设论文撰写课程，
                并组建教授团队，进行模拟答辩，根 据专家教授意见提供论文修改意见。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.Service {
  background-color: #fff;
  position: relative;
  height: 873px;
  .content {
    width: 1199px;
    margin: 0 auto;
    // position: absolute;
    // left: 322px;
    // left: 50%;
    // transform: translateX(-50%);
    // top: 0;
    .title {
      margin-top: 53px;
      margin-bottom: 43px;
      font-weight: 500;
      text-align: center;
      color: #333333;
      font-size: 36px;
    }
    .detail {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      .detailItem {
        &:nth-child(2n) {
          margin-left: 178px;
        }
        width: 510px;
        height: 286px;
        margin-bottom: 40px;
        position: relative;
        box-shadow: 0 2px 30px #dfdfdf, 0 0 30px #dfdfdf, 0 0 30px #dfdfdf;
        .bgc {
          width: 255px;
          height: 64px;
          position: absolute;
          left: -60px;
          top: 21px;
        }
        .bgcImg {
          width: 255px;
          height: 64px;
          position: relative;
          font-size: 36px;
          font-weight: 500;
          color: #fff;
          .num {
            position: absolute;
            left: 14px;
            top: 50%;
            transform: translateY(-50%);
          }
          .explain {
            position: absolute;
            left: 83px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .text {
          position: absolute;
          top: 106px;
          left: 38px;
          color: #333333;
          .textTitle {
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 19px;
          }
          .textDetail {
            width: 394px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
