<template>
  <div class="Projects">
    <!-- <img src="../../../../../assets/college/Accounts/learningAbility/project.png" alt="" /> -->
    <div class="content">
      <div class="title">项目优势</div>
      <div class="detail">
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/exemption.png" alt="" />
          <div class="hover">
            <img src="../../../../../assets/college/Accounts/learningAbility/orange.png" alt="" />
            <div class="text">在职同等学力申硕课程免试入学， 边学边考</div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/major.png" alt="" />
          <div class="hover">
            <img src="../../../../../assets/college/Accounts/learningAbility/orange.png" alt="" />
            <div class="text">金融投资热门专业， 满足职场和发展的需求</div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/teacher.png" alt="" />
          <div class="hover">
            <img src="../../../../../assets/college/Accounts/learningAbility/orange.png" alt="" />
            <div class="text">中南财经政法大学名师讲授， 前瞻性实用知识与技能</div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/quick.png" alt="" />
          <div class="hover">
            <img src="../../../../../assets/college/Accounts/learningAbility/orange.png" alt="" />
            <div class="text">随报随学， 周末线上学习，合理安排工作和生活</div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/coach.png" alt="" />
          <div class="hover">
            <img src="../../../../../assets/college/Accounts/learningAbility/orange.png" alt="" />
            <div class="text">
              从申请入学、课程学习、统考备考、论文答辩的全流程辅导和服务
              保障您轻松、顺利的拿到硕士学位
            </div>
          </div>
        </div>
        <div class="detailItem">
          <img src="../../../../../assets/college/Accounts/learningAbility/platform.png" alt="" />
          <div class="hover">
            <img src="../../../../../assets/college/Accounts/learningAbility/orange.png" alt="" />
            <div class="text">
              丰富的校友活动， 汇聚高端人脉资源， 提供高层次互动与交流
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.Projects {
  height: 841px;
  position: relative;
  padding-top: 57px;
  background: url('../../../../../assets/college/Accounts/learningAbility/project.png') no-repeat
    center center;
  .content {
    width: 1199px;
    margin: 0 auto;
    // position: absolute;
    // left: 263px;
    // left: 50%;
    // transform: translateX(-50%);
    // top: 0;
    .title {
      margin-bottom: 48px;
      color: #ffffff;
      font-size: 36px;
      font-weight: 500;
      text-align: center;
    }
    .detail {
      background-color: rgba(173, 213, 230, 0.5);
      padding: 48px 26px 14px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      .detailItem {
        &:hover > .hover {
          display: block;
        }
        &:nth-child(3n + 2) {
          margin: 0 24px;
        }
        margin-bottom: 24px;
        width: 366px;
        height: 265px;
        position: relative;
        .hover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: none;
        }
        .text {
          width: 239px;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          line-height: 30px;
          color: #fff;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
