<template>
  <div class="about">
    <img src="../../../../../assets/college/Accounts/learningAbility/about.png" alt="" />
    <div class="text">
      <div>
        同等学力申硕，是获得学士学位的工作人士，通过申请参加（免试）特定
        高校开设专业课程学习并完成论文答辩，获得硕士学位的方式。
      </div>
      <div>
        同等学力申硕的课程学习方式灵活，可采用线上学习，不需要线下上课，并且可以
        免试入学，并学边考，是工作人士获得硕士学位的不二之选。
      </div>
      <div>
        在本项目中，中潮教育将根据银行员工的实际情况，制定特色化服务方案，从“入学申请、学习组织、考试辅导、论文辅导”等方面提供全流程多维度系统化服务，
        保障银行等金融机构学员的学历和能力双提升。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.about {
  width: 1213px;
  height: 462px;
  position: relative;
  .text {
    position: absolute;
    left: 353px;
    top: 175px;
    color: #333333;
    line-height: 36px;
    font-size: 16px;
    width: 789px;
    div {
      text-indent: 2em;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
